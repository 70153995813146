import React from "react";

const brandingsList = [
    {
        name: "Essentia",
        domainName: "vatomator.com",
        colorLight: "#6EA19A",
        colorDark: "#423741",
        colorLight20p: "rgba(110, 161, 154, 0.2)",
        logo: () => <div id={"essentia_logo"}>
            <img alt="essentia-logo" src="/src/img/essentia-logo.jpg" width="250" height="auto"/>
            <div id="essentia_logo_border_part"/>
        </div>,
    },
    {
        name: "Essentia Demo",
        domainName: "demo.vatomator.com",
        colorLight: "#6EA19A",
        colorDark: "#423741",
        colorLight20p: "rgba(110, 161, 154, 0.2)",
        logo: () => <div id={"essentia_logo"} style={{backgroundColor: "white", padding: "0 30px"}}>
            <div style={{marginTop: 3}}>
                <span className="uid color" style={{fontSize: 30}}>VAT</span><span style={{color: "#423741", fontSize: 30}}>omator</span>
            </div>
        </div>,
    },
    {
        name: "The VAT Consultancy",
        domainName: "tvc.vatomator.com",
        colorLight: "#990033",
        colorLight20p: "rgba(153, 0, 51, 0.1)",
        colorDark: "#6f6f6f",
        logo: () => <div id={"essentia_logo"} style={{height: "60px", backgroundColor: "white", padding: "0 10px"}}>
            <img alt="essentia-logo" src="https://thevatconsultancy.com/wp-content/uploads/2017/09/the-vat-consultancy-logo.gif" width="320" height="auto"/>
        </div>,
    },
    {
        name: "Markel",
        domainName: "markel.vatomator.com",
        colorLight: "#b71234",
        colorLight20p: "rgba(153, 0, 51, 0.1)",
        colorDark: "#a0a1a3",
        logo: () => <div id={"essentia_logo"}>
            <img alt="essentia-logo" src="/src/branding/markel/MK_R_GG.png" style={{backgroundColor: "white", padding: "3px 10px"}} width="53" height="auto"/>
            <div id="essentia_logo_border_part"/>
        </div>,
    },
    {
        name: "Oury Clark",
        domainName: "oc.vatomator.com",
        colorLight: "#e41b3d",
        colorLight20p: "rgba(149, 3, 49, 0.1)",
        colorDark: "#292929",
        logo: () => <div id={"essentia_logo"}>
            <img alt="essentia-logo" src="/src/branding/ouryclark/ouryclark2.svg" style={{backgroundColor: "white", padding: "3px 10px"}} width="85" height="auto"/>
            <div id="essentia_logo_border_part"/>
        </div>,
    },
    {
        name: "Next Level Business",
        domainName: "nextlevelbusiness.vatomator.com",
        colorLight: "#EB5A4C",
        colorLight20p: "rgba(235, 90, 76, 0.1)",
        colorDark: "#44567A",
        logo: () => <div id={"essentia_logo"}>
            <img alt="essentia-logo" src="/src/branding/nextlevelbusiness/NLB_Logo_Line.png" style={{backgroundColor: "white", padding: "13px 10px"}} width="320" height="auto"/>
            <div id="essentia_logo_border_part"/>
        </div>,
    },
    {
        name: "Eurofiscalis",
        domainName: "eurofiscalis.vatomator.com",
        colorLight: "#5ECD8C",
        colorLight20p: "rgba(94,205,140,0.1)",
        colorDark: "rgba(44,80,81,1)",
        logo: () => <div id={"essentia_logo"}>
            <img alt="essentia-logo" src="/src/branding/eurofiscalis/Eurofiscalis_logo.png" style={{backgroundColor: "white", padding: "13px 10px"}} width="205" height="auto"/>
            <div id="essentia_logo_border_part"/>
        </div>,
    },
    {
        name: "CFGI",
        domainName: "cfgi.vatomator.com",
        colorLight: "#47bdff",
        colorLight20p: "rgba(71,189,255,0.1)",
        colorDark: "rgba(0,42,92,1)",
        logo: () => <div id={"essentia_logo"}>
            <img alt="essentia-logo" src="/src/branding/cfgi/logo.png" style={{backgroundColor: "rgba(34,48,59,1)", padding: "0px 0px 5.5px"}} width="183" height="auto"/>
            <div id="essentia_logo_border_part"/>
        </div>,
    },
]
const brandings = brandingsList.reduce((obj, curr) => ({...obj, [curr.domainName]: curr}), {})
const domainName = window.location.hostname;
//const branding = brandings[domainName] || brandings["vatomator.com"];
//const branding = brandings[domainName] || brandings["oc.vatomator.com"];
//const branding = brandings[domainName] || brandings["tvc.vatomator.com"];
//const branding = brandings[domainName] || brandings["markel.vatomator.com"];
//const branding = brandings[domainName] || brandings["nextlevelbusiness.vatomator.com"];
//const branding = brandings[domainName] || brandings["demo.vatomator.com"];
const branding = brandings[domainName] || brandings["cfgi.vatomator.com"];

export default branding
